import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface JobsState {
  jobs: [
    {
      _id: string;
      job_title: string;
      site_code: string;
      location: string;
      rate_of_pay: string;
      delivery_driver: string;
      description: string;
    }
  ];
  total: number;
  loading: boolean;
}

const initialValue: JobsState = {
  jobs: [
    {
      _id: "",
      job_title: "",
      site_code: "",
      location: "",
      rate_of_pay: "",
      delivery_driver: "",
      description: "",
    },
  ],
  total: 0,
  loading: false,
};

const jobsReducer = createSlice({
  name: "jobs",
  initialState: initialValue,
  reducers: {
    getJobs(state, action) {
      console.log("Action Data", action);
      state.loading = false;
      state.jobs = action.payload.jobs;
      state.total = action.payload.total;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const jobsActions = jobsReducer.actions;
export default jobsReducer.reducer;
