import { SIZE } from "baseui/button";
import { Select as BaseSelect } from "baseui/select";

type SelectProps = React.ComponentProps<typeof BaseSelect>;

export default function Select(props: SelectProps) {
  return (
    <BaseSelect
      size={SIZE.compact}
      backspaceRemoves={false}
      searchable={false}
      {...props}
      overrides={{
        ControlContainer: {
          style: (prop) => {
            const {
              $disabled,
              $error,
              $isFocused,
              $isOpen,
              $theme: { colors },
            } = prop;
            const border = $disabled
              ? colors.borderAlt
              : $error
              ? colors.borderError
              : $isFocused
              ? "#a633db"
              : "#cdcdcd";
            const radius = $isOpen ? "0px" : "4px";
            return {
              borderLeftColor: border,
              borderRightColor: border,
              borderTopColor: border,
              borderBottomColor: border,
              borderTopWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              backgroundColor: "#ffffff",
              borderTopRightRadius: "4px",
              borderBottomRightRadius: radius,
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: radius,
            };
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  boxShadow: "none",
                  zIndex: 1,
                },
              },
            },
          },
        },
        Dropdown: {
          style: {
            boxShadow: "none",
            border: "1px solid #a633db",
            borderTop: "none",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
        },
        ...props.overrides,
      }}
    />
  );
}
