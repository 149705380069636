let lang = "en";
const LanguageService = {
  setLang(data: string) {
    lang = data;
    console.log("Lang==>", lang);
  },
  getLang() {
    console.log("Get Lang==>", lang);
    return lang;
  },
};
export default LanguageService;
