let error: any, success: any;
const ToasterService = {
  showSuccessToaster: function (data: any) {
    success(data);
  },
  showErrorToaster: function (data: any) {
    error(data);
  },

  subscribeToToaster: function (
    success_subscribe_callBack: any,
    error_subscribe_callBack: any
  ) {
    success = success_subscribe_callBack;
    error = error_subscribe_callBack;
  },
};
export default ToasterService;
