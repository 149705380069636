import "./App.css";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import LightTheme from "./_common/LigthTheme";
import Routes from "./Routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppModal from "./_common/modal/AppModal";
import Toaster from "./_common/toaster/Toaster";
import "./translations/i18n";

const engine = new Styletron();

function App() {
  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <Toaster />
          <AppModal />
          <Routes />
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  );
}

export default App;
