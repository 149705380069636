import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Cookies() {
  const { t } = useTranslation();
  return (
    <CookieConsent
      buttonText="Accept"
      cookieName="bring Express GmbH cookies"
      style={{
        color: "#717171",
        background: "rgb(255 232 221)",
        boxShadow: "rgb(251 214 196) 0px 8px 24px",
        left: "5%",
        right: "5%",
        width: "90%",
        top: "2% !important",
        borderRadius: "16px",
      }}
      buttonStyle={{
        color: "#a633db",
        fontSize: "14px",
        background: "#e6c9f5",
        borderRadius: "25px",
        fontWeight: "bold",
        padding: "10px 25px",
      }}
    >
      {t("cookies.data")}
      <Link to="/privacy-policy" className="custom-link">
        {t("cookies.link")}
      </Link>
    </CookieConsent>
  );
}
