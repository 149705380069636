import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollTop({ history }: any) {
  useEffect(() => history.listen(() => window.scrollTo(0, 0)), [history]);

  return null;
}

export default withRouter(ScrollTop);
