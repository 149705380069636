import modalReducer from "./slices/modal/modalReducer";
import { reducer as formReducer } from "redux-form";
import careerReducer from "./slices/careers/careerReducer";
import jobsReducer from "./slices/jobs/jobsReducer";

const rootReducer = {
  form: formReducer,
  modal: modalReducer,
  career: careerReducer,
  jobs: jobsReducer,
};

export default rootReducer;
