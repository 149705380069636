import { createSlice } from "@reduxjs/toolkit";

interface CareerState {
  career: {};
}

const initialValue: CareerState = {
  career: {},
};

const careerReducer = createSlice({
  name: "career",
  initialState: initialValue,
  reducers: {
    addCareer(state, action) {
      console.log("Action Data", action);
      state.career = action.payload;
    },
  },
});

export const careerActions = careerReducer.actions;
export default careerReducer.reducer;
