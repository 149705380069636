export const TRANSLATIONS_EN = {
  header: {
    linka: "Become a Partner ",
    linkb: "About us",
    linkc: "Contact us",
    button: "Careers",
  },
  banner: {
    slogan: "Find a job as a driver",
    heading: "Become a bringExpress driver",
    text: "We work as courier partners for leading companies such as Amazon, UPS, DHL, Zalando, bring, Paket-Taxi, Hermes, Lieferando, Wolt, Uber Eats and many others. Our priority is to save time and money for our partners. ",
    link: "Open positions",
  },
  partner: {
    heading_main: "We are the solution for your company ",
    heading: "Partners",
    slogan: "Trusted by our partners ",
    link: "Register as Partner",
    banner_text:
      "Great things start with a reliable partner. We are committed to providing quality in each and every delivery. Good service is our top priority",
    breadcrumb: {
      a: "Home",
      b: "Partner",
    },
    headinga: {
      a: "Let us take the weight",
      b: "off your shoulders!",
    },
    headingb: {
      a: "Let us manage your",
      b: "courier delivery hassle",
    },
    lista: {
      a: "We are the simple and easiest solution for any kind of logistic companies ",
      b: "We sure your package gets to your customers when they need it",
      c: "No mistake, quick service, professioanl team and expert drivers",
      d: "Working throughout the year ",
      e: "10 years in business ",
    },
    listb: {
      a: "Sit back and relax while your items are safely delivered by us",
      b: "Capable of scaling up the team at any time",
      c: "Using latest vehicles",
      d: "Helping companies to grow more",
      e: "Quality services assured",
      f: "Trusted to Deliver",
    },
    form: {
      heading: "Become a partner",
      company_name: "Company name",
      name: "Name",
      email: "Email",
      phone: "Phone",
      location: "Location",
      comments: "Additional comments",
      submit: "Submit",
    },
  },
  trustbox: {
    heading: "Let us deliver for you",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    link: "Apply now",
  },
  career: {
    heading: "Careers",
    breadcrumb: {
      a: "Home",
      b: "Careers",
    },
    slogan: "You are applying for",
    text: "Become a part of our amazing team. bring Express is an equal opportunity employer, which means we do not discriminate on the basis of race, color, religion, marital status, age, and national origin.",
    form: {
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      phone: "Phone",
      yes: "Yes",
      no: "No",
      under_age:
        "Are you at least 18 years old with the right to live and work in the Germany?",
      driving_license:
        "Do you have a valid Germany Driving License with less than 6 Penalty Points?",
      self_employed:
        "Are you comfortable with being self-employed (Independent Contractor)?",
      agreement:
        'By filling in your details and clicking "Submit", you agree to us passing on your details to Bring Express so that we can inform you directly by email or telephone about job vacancies.',
      submit: "Submit",
    },
  },
  jobs: {
    slogan:
      "Ready to go? Select and apply for your preferred driver role below",
    heading: "Search for the latest opportunities",
    tha: "Job Title",
    thb: "Location",
    thc: "Earnings",
    apply: "Apply",
    no_result: "No results",
  },
  whyus: {
    heading: "Why Choose Us",
    slogan: "A Choice That Makes The Difference",
    headinga: {
      a: "Specializing",
      b: "in timely",
      c: "deliveries",
    },
    texta:
      "We make things happen with unmatched speed, precision, and creativity. We’re faster, more convenient and our prices are lower.",
    headingb: {
      a: "Good service",
      b: "is our top",
      c: "priority",
    },
    textb:
      "We strive to deliver quality with every delivery. Guaranteed on-time deliveries by our dedicated team of friendly and professional drivers.",
    link: "Explore more",
  },
  about: {
    heading: "About Us",
    breadcrumb: {
      a: "Home",
      b: "About Us",
    },
    text: "bring Express GmbH is a courier company operating in Germany that has already successfully delivered more than 20 million parcels. With more than 600 drivers and in cooperation with leading partners around the world.",
    partner: {
      number: "20M",
      text: "Packages delivered",
    },
    driver: {
      number: "600",
      text: "Drivers",
    },
    year: {
      number: "1000",
      text: "Vehciles",
    },
    project: {
      number: "10",
      text: "Years of experience",
    },
    welcome: "bring Express - Your partner for express delivery ",
    welcome_text:
      "By providing our customers and carriers with the highest level of professional service, reliability and peace of mind, we have become one of the largest and most trusted courier companies in Germany, employing more than 600 drivers. We are proud of our long-standing partnerships. No destination is too far, no load too heavy, no task too complex. We make courier delivery easy. And we do more than just a job. We will continue to grow because we are committed to being the leading service provider in the industry.",
  },
  testimonials: {
    heading: "TESTIMONIALS",
    slogan: "Some Words From Our Costumers",
    text: "They talk about it better than us",
  },
  contact: {
    heading: "Contact Us",
    breadcrumb: {
      a: "Home",
      b: "Contact Us",
    },
    text: "Many years of experience in the logistics and transport industry make bring Express your reliable, fast and leading logistics partner. Whether your shipment is small or large or requires special handling for safety reasons - bring Express will get it to your desired destination safely and on time. ",
    text_a:
      "In order to reach support don't hesitate to contact us by submitting the form below ",
    form: {
      heading: "Quick Contact Form",
      name: "Name",
      email: "Email",
      subject: "Subject",
      message: "Message",
      submit: "Submit",
    },
    details: {
      heading: "CONTACT US",
      slogan: "Get in touch with us",
      inner_heading: "Contact us if you need further assistance",
      text: "Headquarters located in berlin, Germany ",
      location: {
        head: "Our Location",
        text: "Müllerstraße 156b, 13353 Berlin",
      },
      phone: {
        head: "Phone",
        text: "030 39 88 11 00",
      },
      email: {
        head: "Email",
        text: "info@bring-express.com",
      },
    },
    subscribe: {
      heading: "Let's Discuss.",
      text: "Send us your email and our team will reach to you. ",
      email: "Enter email",
      button: "Subscribe",
    },
  },
  page: {
    next: "Next",
    prev: "Prev",
  },
  imprint: {
    heading: "Imprint",
    slogan: "The operator of the website bring-express.com is ",
    address_a: "bring Express GmbH",
    address_b: "Sonnenallee 118",
    address_c: "12045 Berlin",
    address_d: "Germany",
    commercial: "Commercial register",
    last: "Court Charlottenburg",
  },
  terms: {
    heading: "Terms of Use",
    list_a: {
      heading: "Preamble",
      text: "bring Express GmbH, Sonnenallee 118, 12045 Berlin, Germany registered in the commercial register of the local court Berlin Charlottenburg under HRB 189552B and represented by the managing director Orhan Mertyüz takes care of the transport of parcel, courier; Express and mail items via a logistics system. The transports themselves are subject to the respective applicable provisions of German law, unless otherwise stipulated below.",
    },
    list_b: {
      heading: "Validity of these terms and conditions",
      text: "Agreements that deviate from the applicable general terms and conditions only become part of the contract if they are recognized in writing by bring Express GmbH.",
    },
    list_c: {
      heading: "Services and prices",
      child: {
        a: "With regard to all transport services offered by bring Express GmbH, the following applies: In principle, all shipments that are suitable for transport by bicycle, motorcycle, car and other motor vehicles can be transported. The transport of people and cash is excluded. The transport of weapons, drugs or other illegal goods is also excluded (especially the delivery of age-restricted goods such as alcohol or tobacco to minors). Dangerous goods or goods, goods and substances that are classified as dangerous goods according to the legal regulations must be registered as such for transport by the client. If the client fails to register as dangerous goods for transport, the general exclusion from the transport of dangerous goods by bring Express GmbH applies.",
        b: "The object of a transport order is the collection and delivery of the goods to be transported to the recipient or a third party authorized to receive them. Unless the client expressly requests a personal handover to the recipient, all shipments can also be handed over to other people who are found at the recipient's address. Written delivery receipts, confirmations of receipt or similar are only requested from the recipient upon express request. Proof of delivery is a printout of the reproduction of the recipient's signature in digitized form and, if applicable, the postage card section signed by him / her.",
        c: "It is the responsibility of the client to hand over the consignments to be transported in packaging suitable for transport or storage. Unpackaged shipments or unsuitable and improperly packaged shipments can be picked up, packed and transported on request. Each consignment intended for transport must be addressed completely and clearly legibly and, if necessary, be identified as a special consignment.",
        d: "A transport order is accepted and carried out as soon as the traffic situation and the disposition of the individual means of transport permit. Compliance with certain delivery dates is only owed if this is expressly agreed. Force majeure, such as natural disasters, weather and weather obstacles, official bans and hindrances, unpredictable closures, strikes, unusual traffic jams or missing or insufficient documentation when placing the order or additional instructions that influence the transport process, release bring Express GmbH from any promise of delivery time. If there is no express agreement, the transport fee is based on the price recommendations of bring Express GmbH that were valid at the time the contract was concluded. The basis of the billing is the respective commissioned or provided service in accordance with the currently applicable tariffs or separate agreements. The transport fee is due when the order is placed and is to be paid cashlessly. bring Express GmbH invoices the services provided in the form of an invoice being sent by email.",
        e: "The transport fee is due immediately and without any deductions. If the client does not pay even after receiving a reminder, bring Express GmbH can demand a reminder fee of € 5.00 for the second reminder as well as default interest of 5% above the applicable base rate. The assertion of further damage caused by default remains unaffected. In addition, the client undertakes to reimburse the costs incurred in pursuing the overdue invoice amount through the involvement of a debt collection company in the amount of a full attorney's fee plus flat-rate expenses in accordance with BRAGO. If the client has objections to the invoice, these must be made in writing within fourteen (14) days, but no later than after receipt of the first reminder.",
        f: "If the client cancels the transport order at a point in time at which the delivery process is already in progress (in particular a courier has accepted the order), the full transport fee is due.",
      },
    },
    list_d: {
      heading: "Inch",
      child: {
        a: "The client must bring all the documents required for customs clearance. By submitting the required documents, the client confirms that all declarations, export and import information are truthful and correct. The client is aware that incorrect statements made with fraudulent intent can have consequences under civil and criminal law, including confiscation and sale of the goods",
        b: "When the consignment is handed over to the courier, bring Express is commissioned with customs clearance as a customs agent as far as this is permissible. bring Express is used as the nominal recipient for the purpose of commissioning a customs broker to handle the customs formalities. For customs clearance, the tariff surcharges apply according to the current bring Express price list.",
        c: "Customs penalties, storage fees and other costs incurred as a result of actions by the customs authorities or due to the failure of the client or the recipient to present the required export documents, licenses or certificates of approval will be invoiced to the recipient with customs duties and taxes if the recipient has the right to delivery the shipment asserts. If the recipient does not meet his payment obligation immediately, the client is liable.",
      },
    },
    list_e: {
      heading: "Liability",
      child_a: {
        heading: "General",
        inner_a: {
          heading_a:
            "bring Express GmbH is only liable for damage, regardless of the legal reason, including tort, insofar as it",
          inner_child_a: {
            a: "caused by culpable breach of essential contractual obligations (so-called cardinal obligations) by bring Express GmbH in a way that endangers the achievement of the contractual purpose or",
            b: "are due to gross negligence or intent on the part of bring Express GmbH.",
          },
          heading_b:
            "If bring Express GmbH is liable for the breach of an essential contractual obligation without being guilty of gross negligence or intent, the liability of the contractor is limited to the foreseeable damage typical for the contract. This is usually € 1,000.00 (see section 5.3).",
          heading_c:
            "If bring Express GmbH is liable in accordance with section 5.1.2 for gross negligence or willful misconduct by employees who are not the managing directors of the contractor, bring Express GmbH's liability shall also apply to the conditions set out in section 5.1.2. mentioned maximum amount.",
          heading_d:
            "bring Express GmbH is not liable for indirect damage, consequential damage due to defects, lost profit or the reimbursement of wasted expenses, unless these are due to intent or gross negligence on the part of the managing directors of bring Express GmbH.",
          heading_e:
            "The exclusion or limitation of claims according to the preceding paragraphs also applies to claims against employees and agents of bring Express GmbH.",
          heading_f:
            "bring Express GmbH is liable for damage resulting from injury to life, limb or health or an assumed guarantee.",
          heading_g:
            "If the customer is also responsible for the damage, the customer has to allow his contributory negligence to be taken into account. As part of its duty to minimize damage, the customer must ensure that packaging is appropriate (see Section 5.4).",
        },
        heading_b:
          "Notification of damage: Identifiable damage and shortages must be reported to bring Express GmbH immediately in text form when the goods are accepted by the recipient. Not immediately recognizable damage or shortages are to be reported to bring Express GmbH in writing immediately after their discovery, at the latest however within seven days after acceptance of the transported goods by the recipient. General reservations such as “not checked” or “subject to reservation” upon acceptance by the recipient do not count as notification of damage or shortages. The lack of delivery receipts must be asserted in writing to bring Express GmbH within three days of delivery. If the deadlines specified in this section 5.2 are not met, bring Express GmbH will not be liable.",
        heading_c:
          "Maximum limits. bring Express GmbH is liable for any transport in the event of loss or damage to the goods in transit with a replacement service up to an amount of € 1,000, but no more than the value of the damaged or lost goods - in each case per shipment. The customer can increase this maximum limit by taking out appropriate transport insurance at his own expense. The compensation described above will not be granted if the goods are insured for transport by someone else (e.g. interested parties, the customer or the recipient). The amount of the desired sum insured must be specified in this order. In the case of films, DVDs and other data carriers, liability is limited to the value of the material. For valuables, Jewelry and works of art must be agreed in writing between the client and bring Express GmbH in writing before transport and for the shipment. If the promised term of one or more shipments is not adhered to in the case of international transports, the amount of the compensation is limited to the simple freight charge. Force majeure of any kind releases bring Express GmbH from any promise of term.",
        heading_d:
          "Special cases, packaging. Liability is excluded if the loss, damage or exceeding the delivery deadline is due to circumstances which the employees of bring Express GmbH, the commissioned companies and couriers could not avoid even with the greatest care and whose consequences they could not avert. Liability for breakage damage to glass, porcelain and similar fragile goods or device parts is excluded, unless otherwise expressly agreed individually, with the exception of willful or grossly negligent damage by employees of bring Express GmbH, the contracted companies and couriers. Technical devices, models and comparable goods must be properly secured against knocks and impacts in boxes or cardboard boxes with adequate inner packaging.",
        heading_e:
          "Statute of limitations. All claims against bring Express GmbH, contracted companies, couriers and vicarious agents of bring Express GmbH, regardless of the legal reason, become statute-barred after one year or, in the case of intent, after three years. The limitation period begins with the due date of the claim, at the latest with the delivery of the goods, in the case of loss with the time of knowledge of the loss.",
        heading_f:
          "In addition, the limitations of liability according to HGB and ADSp apply.",
      },
      heading_b: "General",
      child_b: {
        a: "The transport orders as well as issued service orders are subject exclusively to the law of the Federal Republic of Germany. The place of performance is the headquarters of bring Express GmbH. As far as legally permissible, the exclusive place of jurisdiction for all legal disputes arising from or in connection with these contracts is Berlin.",
        b: "Should individual provisions of these terms and conditions be or become ineffective or unenforceable, this shall not affect the effectiveness and enforceability of the remaining provisions. An invalid or unenforceable provision should be replaced by the parties in accordance with the standards of good faith in such a way that the intended economic purpose is achieved in the best possible way. The same applies to the filling of undetected loopholes.",
      },
    },
    date: "As of January 1st, 2018",
    protection: {
      heading: "Data protection",
      text: "We know that careful handling of your personal information is important to you. We therefore appreciate your trust that bring Express GmbH will handle this information conscientiously. With this declaration you give us your consent that messenger may collect, process and use your personal data for the purposes mentioned here. This consent can be revoked at any time with future effect. Responsible body within the meaning of the Federal Data Protection Act for the website www. bring Express.de is: bring Express GmbH, Chauseestrasse 60 b, 10115 Berlin. Managing director: Orhan Mertyüz. District court Berlin-Charlottenburg HRB 189552B. Sales tax identification number (UstID): DE301857644",
    },
    personal: {
      heading: "Personal data",
      text: "If you send bring Express GmbH a message or order via the website, we need additional information from you, namely your name, the exact postal address, possibly a telephone number and an email address. The data will only be used to process your request, orders, delivery of goods and rendering of services, as well as processing the payment (also for necessary invoice checks). We also use your information to communicate with you about orders, products and services, to regularly update our data records and to maintain and maintain your customer accounts with us. Transfer to third partiesbring Express GmbH does not sell any personal data of customers and only leaves this data to third parties if they are partner companies that are involved in the provision of the service.",
    },
  },
  footer: {
    copyright: "Copyright 2021 bring Express GmbH.",
    rights: "All rights reserved.",
    colomna: {
      head: "Company",
      linka: "About us",
      linkb: "Contact Us",
      linkc: "Jobs",
    },
    colomnb: {
      head: "Legal",
      linka: "Imprint",
      linkb: "Terms of Use",
      linkc: "Privacy policy",
    },
    colomnc: {
      head: "Get in touch",
    },
  },
  cookies: {
    data: "We use cookies to make our service easier and faster for you to use, to personalise content for you and to analyse website traffic. You can find more information and withdraw your consent at any time by visiting ",
    link: " Privacy policy",
  },
  errors: {
    required: "Required",
    email: "Invalid email address",
    number: "Must be a number",
    positive: "Must be positive",
  },
  response: {
    contact: "Your application sent successfully",
    subscribe: "Your are subscribed successfully",
    career: "Your request sent successfully",
  },
  backlink: "Back",
};
