import { createTheme } from "baseui";

const primitives = {
  accent: "#A633DB", // hot pink
  accent50: "#F16B2F",
  accent100: "#FFFFFF",
  accent200: "#F89FF3",
  accent300: "#F45AEA",
  accent400: "#F127E4",
  accent500: "#B71DAD",
  accent600: "#901788",
  accent700: "#600F5B",

  primaryFontFamily: "'Bring Font', sans-serif",
};

const overrides = {
  colors: {
    buttonSecondaryFill: primitives.accent,
    buttonSecondaryText: primitives.accent100,
    buttonSecondaryHover: primitives.accent50,
    buttonSecondaryActive: primitives.accent50,
    typography: {
      DisplayLarge: {
        fontFamily: "'Bring Font', sans-serif",
      },
      DisplayMedium: {
        fontFamily: "'Bring Font', sans-serif",
      },
      DisplaySmall: {
        fontFamily: "'Bring Font', sans-serif",
      },
      DisplayXSmall: {
        fontFamily: "'Bring Font', sans-serif",
      },
    },
  },
};

const LightTheme = createTheme(primitives, overrides);

export default LightTheme;
