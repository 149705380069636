import { Modal, SIZE } from "baseui/modal";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { modalActions } from "../../redux/slices/modal/modalReducer";

function AppModal() {
  const dispatch = useAppDispatch();

  const { type, isOpen } = useAppSelector((state) => state.modal);
  console.log("Type", type);
  return (
    <Modal
      isOpen={isOpen}
      size={SIZE.auto}
      unstable_ModalBackdropScroll={true}
      onClose={() => dispatch(modalActions.closeModal())}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
        Dialog: {
          style: {
            width: "60%",
          },
        },
      }}
    >
      {/* {type === ADD_JOB && <JobForm />} */}
    </Modal>
  );
}
export default AppModal;
