export const TRANSLATIONS_DE = {
  header: {
    linka: "Partner werden",
    linkb: "Über uns",
    linkc: "Kontakt",
    button: "Karriere",
  },
  banner: {
    slogan: "Job als Fahrer finden",
    heading: "werde bring Express Fahrer",
    text: "Wir arbeiten als Kurierpartner für führende Unternehmen wie Amazon, UPS, DHL, Zalando, bring, Paket-Taxi, Hermes, Lieferando, Wolt, Uber Eats und viele andere. Unsere Priorität ist es, Zeit und Geld für unsere Partner zu sparen.",
    link: "Freie Stellen",
  },
  partner: {
    heading_main: "Wir sind die Lösung für Ihr Unternehmen ",
    heading: "Partner",
    slogan: "Das Vertrauen unserer Partner ",
    link: "Als Partner registrieren",
    banner_text:
      "Große Dinge beginnen mit einem zuverlässigen Partner. Wir haben uns verpflichtet, bei jeder Lieferung Qualität zu liefern. Guter Service ist unsere oberste Priorität",
    breadcrumb: {
      a: "Heim",
      b: "Partner",
    },
    headinga: {
      a: "Werden Sie in unserem",
      b: "Dashboard vorgestellt",
    },

    headingb: {
      a: "Wir nehmen die Last",
      b: "von Ihren Schultern!",
    },
    lista: {
      a: "Wir sind die einfache und leichteste Lösung für jede Art von Logistikunternehmen",
      b: "Wir sorgen dafür, dass Ihr Paket zum richtigen Zeitpunkt bei Ihren Kunden ankommt",
      c: "Keine Fehler, schneller Service, professionelles Team und erfahrene Fahrer ",
      d: "Wir arbeiten das ganze Jahr über ",
      e: "10 Jahre im Geschäft ",
    },
    listb: {
      a: "Lehnen Sie sich zurück und entspannen Sie sich, während Ihre Artikel sicher von uns geliefert werden",
      b: "Das Team kann jederzeit aufgestockt werden",
      c: "Einsatz der neuesten Fahrzeuge  ",
      d: "Unterstützung des Unternehmens beim Wachstum ",
      e: "Garantierte Qualität der Dienstleistung",
      f: "Vertrauen in die Lieferung",
    },
    form: {
      heading: "Werden Sie Partner",
      company_name: "Name des Unternehmens",
      name: "Name",
      email: "E-mail",
      phone: "Telefon",
      location: "Standort",
      comments: "Zusätzliche Kommentare",
      submit: "Senden",
    },
  },
  trustbox: {
    heading: "Lassen Sie uns für Sie liefern",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    link: "Jetzt bewerben",
  },
  career: {
    heading: "Karriere",
    breadcrumb: {
      a: "Heim",
      b: "Karriere",
    },
    slogan: "Sie bewerben sich",
    text: "Werden Sie Teil unseres großartigen Teams. bring Express ist ein Arbeitgeber der Chancengleichheit, das heißt, wir diskriminieren nicht aufgrund von Rasse, Hautfarbe, Religion, Familienstand, Alter oder nationaler Herkunft.",
    form: {
      first_name: "Vorname",
      last_name: "Nachname",
      email: "Email",
      phone: "Telefon",
      yes: "Jawohl",
      no: "Nein",
      under_age:
        "Sie sind mindestens 18 Jahre alt und haben das Recht, in Deutschland zu leben und zu arbeiten?",
      driving_license:
        "Besitzen Sie einen gültigen deutschen Führerschein mit weniger als 6 Strafpunkten?",
      self_employed:
        "Fühlen Sie sich wohl damit, selbstständig zu sein (unabhängiger Auftragnehmer)?",
      agreement:
        'Indem Sie Ihre Daten ausfüllen und auf "Absenden" klicken, erklären Sie sich damit einverstanden, dass wir Ihre Daten an Bring Express weitergeben, damit wir Sie direkt per E-Mail oder Telefon über freie Stellen informieren können.',
      submit: "Einreichen",
    },
  },
  jobs: {
    slogan:
      "BEREIT ZUR BEWERBUNG? WÄHLEN SIE UNTEN IHRE BEVORZUGTE ROLLE UND BEWERBEN SIE SICH",
    heading: "Suche nach den neuesten Möglichkeiten",
    tha: "Stellenbezeichnung",
    thb: "Ort",
    thc: "Gehalt",
    apply: "Bewerbung",
    no_result: "Keine Ergebnisse",
  },
  whyus: {
    heading: "WARUM WÄHLEN SIE UNS",
    slogan: "Eine Wahl, die den Unterschied macht",
    headinga: {
      a: "Spezialisiert",
      b: "auf pünktliche",
      c: "Lieferungen",
    },
    texta:
      "Wir setzen Dinge mit unübertroffener Geschwindigkeit, Präzision und Kreativität um. Wir sind schneller, bequemer und unsere Preise sind niedriger.",
    headingb: {
      a: "Guter Service ist",
      b: "unsere oberste",
      c: "Priorität",
    },
    textb:
      "Wir bemühen uns, bei jeder Lieferung Qualität zu liefern. Garantiert pünktliche Lieferungen durch unser engagiertes Team von freundlichen und professionellen Fahrern.",
    link: "Erkunde mehr",
  },
  about: {
    heading: "Über uns",
    breadcrumb: {
      a: "Heim",
      b: "Über uns",
    },
    text: "bring Express GmbH ist ein in Deutschland tätiges Kurierunternehmen, das bereits mehr als 20 Millionen Pakete erfolgreich zugestellt hat. Mit mehr als 600 Fahrern und in Zusammenarbeit mit führenden Partnern auf der ganzen Welt. ",
    partner: {
      number: "20M",
      text: "Gelieferte Pakete ",
    },
    driver: {
      number: "600",
      text: "Fahrer",
    },
    year: {
      number: "1000",
      text: "Fahrzeuge",
    },
    project: {
      number: "10",
      text: "Jahre Erfahrung",
    },
    welcome: "bring Express - Ihr Partner für Expresssendungen",
    welcome_text:
      "Indem wir unseren Kunden und Spediteuren ein Höchstmaß an professionellem Service, Zuverlässigkeit und Sicherheit bieten, haben wir uns zu einem der größten und vertrauenswürdigsten Kurierunternehmen in Deutschland entwickelt.Unternehmen in Deutschland geworden und beschäftigen mehr als 600 Fahrer. Wir sind stolz auf unsere langjährigen Partnerschaften. Kein Ziel ist zu weit, keine Ladung zu schwer, keine Aufgabe zu komplex. Wir machen Kurierfahrten einfach. Und wir machen mehr als nur einen Job. Wir werden weiter wachsen, weil wir uns verpflichtet haben, der führende Dienstleister in der Branche zu sein.",
  },
  testimonials: {
    heading: "REFERENZEN",
    slogan: "Einige Worte von unseren Kunden",
    text: "Sie reden besser darüber als wir",
  },
  contact: {
    heading: "Kontakt",
    breadcrumb: {
      a: "Heim",
      b: "Kontaktiere uns",
    },
    text: "Langjährige Erfahrung in der Logistik- und Transportbranche machen bring Express zu Ihrem zuverlässigen, schnellen und führenden Logistikpartner. Ob Ihre Sendung klein oder groß ist oder aus Sicherheitsgründen ein besonderes Handling erfordert - bring Express bringt sie sicher und pünktlich an Ihr gewünschtes Ziel. ",
    text_a:
      "Um Unterstützung zu erhalten, zögern Sie nicht, sich mit uns in Verbindung zu setzen, indem Sie das folgende Formular ausfüllen ",
    form: {
      heading: "Schnelles Kontaktformular",
      name: "Name",
      email: "Email",
      subject: "Gegenstand",
      message: "Nachricht",
      submit: "Einreichen",
    },
    details: {
      heading: "KONTAKT",
      slogan: "Nehmen Sie Kontakt mit uns auf",
      inner_heading: "Kontaktieren Sie uns, wenn Sie weitere Hilfe benötigen",
      text: "Hauptsitz in berlin, Deutschland ",
      location: {
        head: "Our Location",
        text: "Müllerstraße 156b, 13353 Berlin",
      },
      phone: {
        head: "Telefon",
        text: "030 39 88 11 00",
      },
      email: {
        head: "E-mail",
        text: "info@bring-express.com",
      },
    },
    subscribe: {
      heading: "Lassen Sie uns darüber reden.",
      text: "Senden Sie uns Ihre E-Mail und unser Team wird sich bei Ihnen melden. ",
      email: "E-mail eingeben",
      button: "Senden",
    },
  },
  page: {
    next: "Näch",
    prev: "Vorh",
  },
  imprint: {
    heading: "Impressum",
    slogan: "Betreiber der Website von bring-express.com ist die ",
    address_a: "bring Express GmbH",
    address_b: "Sonnenallee 118",
    address_c: "12045 Berlin",
    address_d: "Germany",
    commercial: "Handelsregister",
    last: "Amtsgericht Charlottenburg",
  },

  terms: {
    heading: "Terms of Use",
    list_a: {
      heading: "Preamble",
      text: "bring Express GmbH, Sonnenallee 118, 12045 Berlin, Germany registered in the commercial register of the local court Berlin Charlottenburg under HRB 189552B and represented by the managing director Orhan Mertyüz takes care of the transport of parcel, courier; Express and mail items via a logistics system. The transports themselves are subject to the respective applicable provisions of German law, unless otherwise stipulated below.",
    },
    list_b: {
      heading: "Validity of these terms and conditions",
      text: "Agreements that deviate from the applicable general terms and conditions only become part of the contract if they are recognized in writing by bring Express GmbH.",
    },
    list_c: {
      heading: "Services and prices",
      child: {
        a: "With regard to all transport services offered by bring Express GmbH, the following applies: In principle, all shipments that are suitable for transport by bicycle, motorcycle, car and other motor vehicles can be transported. The transport of people and cash is excluded. The transport of weapons, drugs or other illegal goods is also excluded (especially the delivery of age-restricted goods such as alcohol or tobacco to minors). Dangerous goods or goods, goods and substances that are classified as dangerous goods according to the legal regulations must be registered as such for transport by the client. If the client fails to register as dangerous goods for transport, the general exclusion from the transport of dangerous goods by bring Express GmbH applies.",
        b: "The object of a transport order is the collection and delivery of the goods to be transported to the recipient or a third party authorized to receive them. Unless the client expressly requests a personal handover to the recipient, all shipments can also be handed over to other people who are found at the recipient's address. Written delivery receipts, confirmations of receipt or similar are only requested from the recipient upon express request. Proof of delivery is a printout of the reproduction of the recipient's signature in digitized form and, if applicable, the postage card section signed by him / her.",
        c: "It is the responsibility of the client to hand over the consignments to be transported in packaging suitable for transport or storage. Unpackaged shipments or unsuitable and improperly packaged shipments can be picked up, packed and transported on request. Each consignment intended for transport must be addressed completely and clearly legibly and, if necessary, be identified as a special consignment.",
        d: "A transport order is accepted and carried out as soon as the traffic situation and the disposition of the individual means of transport permit. Compliance with certain delivery dates is only owed if this is expressly agreed. Force majeure, such as natural disasters, weather and weather obstacles, official bans and hindrances, unpredictable closures, strikes, unusual traffic jams or missing or insufficient documentation when placing the order or additional instructions that influence the transport process, release bring Express GmbH from any promise of delivery time. If there is no express agreement, the transport fee is based on the price recommendations of bring Express GmbH that were valid at the time the contract was concluded. The basis of the billing is the respective commissioned or provided service in accordance with the currently applicable tariffs or separate agreements. The transport fee is due when the order is placed and is to be paid cashlessly. bring Express GmbH invoices the services provided in the form of an invoice being sent by email.",
        e: "The transport fee is due immediately and without any deductions. If the client does not pay even after receiving a reminder, bring Express GmbH can demand a reminder fee of € 5.00 for the second reminder as well as default interest of 5% above the applicable base rate. The assertion of further damage caused by default remains unaffected. In addition, the client undertakes to reimburse the costs incurred in pursuing the overdue invoice amount through the involvement of a debt collection company in the amount of a full attorney's fee plus flat-rate expenses in accordance with BRAGO. If the client has objections to the invoice, these must be made in writing within fourteen (14) days, but no later than after receipt of the first reminder.",
        f: "If the client cancels the transport order at a point in time at which the delivery process is already in progress (in particular a courier has accepted the order), the full transport fee is due.",
      },
    },
    list_d: {
      heading: "Inch",
      child: {
        a: "The client must bring all the documents required for customs clearance. By submitting the required documents, the client confirms that all declarations, export and import information are truthful and correct. The client is aware that incorrect statements made with fraudulent intent can have consequences under civil and criminal law, including confiscation and sale of the goods",
        b: "When the consignment is handed over to the courier, bring Express is commissioned with customs clearance as a customs agent as far as this is permissible. bring Express is used as the nominal recipient for the purpose of commissioning a customs broker to handle the customs formalities. For customs clearance, the tariff surcharges apply according to the current bring Express price list.",
        c: "Customs penalties, storage fees and other costs incurred as a result of actions by the customs authorities or due to the failure of the client or the recipient to present the required export documents, licenses or certificates of approval will be invoiced to the recipient with customs duties and taxes if the recipient has the right to delivery the shipment asserts. If the recipient does not meet his payment obligation immediately, the client is liable.",
      },
    },
    list_e: {
      heading: "LIABILITY",
      child_a: {
        heading: "General",
        inner_a: {
          heading_a:
            "bring Express GmbH is only liable for damage, regardless of the legal reason, including tort, insofar as it",
          inner_child_a: {
            a: "caused by culpable breach of essential contractual obligations (so-called cardinal obligations) by bring Express GmbH in a way that endangers the achievement of the contractual purpose or",
            b: "are due to gross negligence or intent on the part of bring Express GmbH.",
          },
          heading_b:
            "If bring Express GmbH is liable for the breach of an essential contractual obligation without being guilty of gross negligence or intent, the liability of the contractor is limited to the foreseeable damage typical for the contract. This is usually € 1,000.00 (see section 5.3).",
          heading_c:
            "If bring Express GmbH is liable in accordance with section 5.1.2 for gross negligence or willful misconduct by employees who are not the managing directors of the contractor, bring Express GmbH's liability shall also apply to the conditions set out in section 5.1.2. mentioned maximum amount.",
          heading_d:
            "bring Express GmbH is not liable for indirect damage, consequential damage due to defects, lost profit or the reimbursement of wasted expenses, unless these are due to intent or gross negligence on the part of the managing directors of bring Express GmbH.",
          heading_e:
            "The exclusion or limitation of claims according to the preceding paragraphs also applies to claims against employees and agents of bring Express GmbH.",
          heading_f:
            "bring Express GmbH is liable for damage resulting from injury to life, limb or health or an assumed guarantee.",
          heading_g:
            "If the customer is also responsible for the damage, the customer has to allow his contributory negligence to be taken into account. As part of its duty to minimize damage, the customer must ensure that packaging is appropriate (see Section 5.4).",
        },
        heading_b:
          "Notification of damage: Identifiable damage and shortages must be reported to bring Express GmbH immediately in text form when the goods are accepted by the recipient. Not immediately recognizable damage or shortages are to be reported to bring Express GmbH in writing immediately after their discovery, at the latest however within seven days after acceptance of the transported goods by the recipient. General reservations such as “not checked” or “subject to reservation” upon acceptance by the recipient do not count as notification of damage or shortages. The lack of delivery receipts must be asserted in writing to bring Express GmbH within three days of delivery. If the deadlines specified in this section 5.2 are not met, bring Express GmbH will not be liable.",
        heading_c:
          "Maximum limits. bring Express GmbH is liable for any transport in the event of loss or damage to the goods in transit with a replacement service up to an amount of € 1,000, but no more than the value of the damaged or lost goods - in each case per shipment. The customer can increase this maximum limit by taking out appropriate transport insurance at his own expense. The compensation described above will not be granted if the goods are insured for transport by someone else (e.g. interested parties, the customer or the recipient). The amount of the desired sum insured must be specified in this order. In the case of films, DVDs and other data carriers, liability is limited to the value of the material. For valuables, Jewelry and works of art must be agreed in writing between the client and bring Express GmbH in writing before transport and for the shipment. If the promised term of one or more shipments is not adhered to in the case of international transports, the amount of the compensation is limited to the simple freight charge. Force majeure of any kind releases bring Express GmbH from any promise of term.",
        heading_d:
          "Special cases, packaging. Liability is excluded if the loss, damage or exceeding the delivery deadline is due to circumstances which the employees of bring Express GmbH, the commissioned companies and couriers could not avoid even with the greatest care and whose consequences they could not avert. Liability for breakage damage to glass, porcelain and similar fragile goods or device parts is excluded, unless otherwise expressly agreed individually, with the exception of willful or grossly negligent damage by employees of bring Express GmbH, the contracted companies and couriers. Technical devices, models and comparable goods must be properly secured against knocks and impacts in boxes or cardboard boxes with adequate inner packaging.",
        heading_e:
          "Statute of limitations. All claims against bring Express GmbH, contracted companies, couriers and vicarious agents of bring Express GmbH, regardless of the legal reason, become statute-barred after one year or, in the case of intent, after three years. The limitation period begins with the due date of the claim, at the latest with the delivery of the goods, in the case of loss with the time of knowledge of the loss.",
        heading_f:
          "In addition, the limitations of liability according to HGB and ADSp apply.",
      },
    },
  },
  footer: {
    copyright: "Copyright 2021 bring Express GmbH.",
    rights: "Alle Rechte vorbehalten.",
    colomna: {
      head: "Gesellschaft",
      linka: "Über uns",
      linkb: "Kontakt",
      linkc: "Karriere",
    },
    colomnb: {
      head: "Rechtliches",
      linka: "Impressum",
      linkb: "Terms of Use",
      linkc: "Privacy policy",
    },
    colomnc: {
      head: "Kontakt",
    },
  },
  cookies: {
    data: "Wir verwenden Cookies, um die Nutzung unseres Dienstes für Sie einfacher und schneller zu machen, um Inhalte für Sie zu personalisieren und um den Website-Verkehr zu analysieren. Weitere Informationen und die Möglichkeit, Ihre Zustimmung jederzeit zu widerrufen, finden Sie unter",
    link: " Datenschutz",
  },
  errors: {
    required: "Erforderlich",
    email: "Ungültige E-Mail Adresse",
    number: "Muss eine Zahl sein",
    positive: "Muss positiv sein",
  },

  response: {
    contact: "Ihre Bewerbung wurde erfolgreich gesendet",
    subscribe: "Sie haben sich erfolgreich angemeldet",
    career: "Ihre Anfrage wurde erfolgreich gesendet",
  },
  backlink: "Zurück",
};
