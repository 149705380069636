import Logo from "../../_assets/images/logo.png";
import "../../_assets/css/header.css";
import { Link } from "react-router-dom";
import { useLocation, withRouter } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Select from "../../_common/select/Select";
import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import LanguageService from "../../_services/language.service";

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);
  const [language, setLanguage] = useState([
    {
      label: i18n.language === "en" ? "English" : "Deutsch",
      id: i18n.language,
    },
  ]);

  const handleOnclick = (data: any) => {
    setLanguage(data);
    i18next.changeLanguage(data[0].id);
    LanguageService.setLang(data[0].id);
    document.documentElement.lang = data[0].id
    closeMobileMenu();
  };

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <div className="mob-header"></div>
      <div className="header">
        <nav className="navbar">
          <div className="navbar-brand">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <ul className={click ? "nav-list responsive" : "nav-list"}>
            <li className="nav-item">
              <Select
                options={[
                  { label: "En", id: "en" },
                  { label: "De", id: "de" },
                ]}
                clearable={false}
                value={language}
                onChange={(params) => handleOnclick(params.value)}
                overrides={{
                  Root: {
                    style: {
                      marginRight: "0px",
                    },
                  },
                  ControlContainer: {
                    style: {
                      borderTopWidth: "0px !important",
                      borderLeftWidth: "0px !important",
                      borderRightWidth: "0px !important",
                      borderBottomWidth: "0px !important",
                      backgroundColor: "#fafafa",
                      borderTopRightRadius: "25px",
                      borderTopLeftRadius: "25px",
                      borderBottomLeftRadius: "25px",
                      borderBottomRightRadius: "25px",
                    },
                  },
                  ValueContainer: {
                    style: {
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    },
                  },
                  Dropdown: {
                    style: {
                      border: "0px !important",
                    },
                  },
                  IconsContainer: {
                    style: {
                      display: "none",
                    },
                  },
                }}
              />
            </li>
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link
                to="/partners"
                className={`${pathname === "/partners" && "active"}`}
              >
                {t("header.linka")}
              </Link>
            </li>
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link
                to="/about-us"
                className={`${pathname === "/about-us" && "active"}`}
              >
                {t("header.linkb")}
              </Link>
            </li>
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link
                to="/contact-us"
                className={`${pathname === "/contact-us" && "active"}`}
              >
                {t("header.linkc")}
              </Link>
            </li>
            <li className="nav-item header-btn" onClick={closeMobileMenu}>
              <Link to="/careers">{t("header.button")} </Link>
            </li>
          </ul>
          <div className="mobile-menu" onClick={handleClick}>
            {click ? (
              <AiOutlineClose fill="#ff6c2f" className="menu-icon" />
            ) : (
              <AiOutlineMenu fill="#a633db" className="menu-icon" />
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default withRouter(Header);
