import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Header from "./components/layout/Header";
import Cookies from "./Cookies";
import ScrollTop from "./_utils/ScrollTop";

/**************************** Basic Path ***************************/
const Home = lazy(() => import("./components/pages/home/Home"));
const ContactUs = lazy(() => import("./components/pages/contact/ContactUs"));
const AboutUs = lazy(() => import("./components/pages/about/AboutUs"));
const Career = lazy(
  () => import("./components/pages/career/careerForm/Career")
);
const Partners = lazy(() => import("./components/pages/partners/Partner"));
const Jobs = lazy(() => import("./components/pages/career/jobs/Jobs"));
const Imprint = lazy(() => import("./components/pages/legal/imprint/Imprint"));
const PrivacyPolicy = lazy(
  () => import("./components/pages/legal/privacy/PrivacyPolicy")
);
const TermsConditions = lazy(
  () => import("./components/pages/legal/terms/TermsConditions")
);
const Routes = () => {
  return (
    <Router>
      <Header />
      <Cookies />
      <ScrollTop />
      <Suspense fallback="Loading">
        <Switch>
          {/**************************** Home Routes ***************************/}
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/career/:id" component={Career} />
          <Route exact path="/careers" component={Jobs} />
          <Route exact path="/partners" component={Partners} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsConditions} />
          <Route exact path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
};
export default Routes;
